import '../reactInit';
import 'intersection-observer';
import type { ComponentType } from 'react';
import { registerComponent } from '~/Features/react-components/utils';

const ComponentsDynamicImports: Record<string, () => Promise<ComponentType<any>>> = {
    AccordionBlock: () =>
        import(/* webpackChunkName: "AccordionBlock" */ '~/Blocks/AccordionBlock/AccordionBlock').then(
            exports => exports.AccordionBlock
        ),
    AddToApplication: () =>
        import(/* webpackChunkName: "AddToApplication" */ '~/Partials/AddToApplication/AddToApplication').then(
            exports => exports.AddToApplication
        ),
    AreasOfExpertise: () =>
        import(/* webpackChunkName: "AreasOfExpertise" */ '~/Pages/AreasOfExpertisePage/AreasOfExpertise').then(
            exports => exports.AreasOfExpertise
        ),
    ArticleListAnchorSelect: () =>
        import(
            /* webpackChunkName: "ArticleListAnchorSelect" */ '~/Blocks/ArticleListAnchorSelect/ArticleListAnchorSelect'
        ).then(exports => exports.ArticleListAnchorSelect),
    PosterListBlock: () =>
        import(/* webpackChunkName: "PosterListBlock" */ '~/Blocks/PosterListBlock/PosterListBlock').then(
            exports => exports.PosterListBlock
        ),
    BuySubjectButton: () =>
        import(/* webpackChunkName: "BuySubjectButton" */ '~/Partials/BuySubjectButton/BuySubjectButton').then(
            exports => exports.BuySubjectButton
        ),
    CarouselBlock: () =>
        import(/* webpackChunkName: "CarouselBlock" */ '~/Blocks/CarouselBlock/CarouselBlock').then(
            exports => exports.CarouselBlock
        ),
    ContactUsBlock: () =>
        import(/* webpackChunkName: "ContactUsBlock" */ '~/Blocks/ContactUsBlock/ContactUsBlock').then(
            exports => exports.ContactUsBlock
        ),
    ContentSearch: () =>
        import(/* webpackChunkName: "ContentSearch" */ '~/Pages/ContentSearchPage/ContentSearch/ContentSearch').then(
            exports => exports.ContentSearch
        ),
    CalloutListWithAlert: () =>
        import(
            /* webpackChunkName: "CalloutListWithAlert" */ '~/Blocks/CalloutListWithAlertBlock/CalloutListWithAlert'
        ).then(exports => exports.CalloutListWithAlert),
    CookiesConsent: () =>
        import(/* webpackChunkName: "CookiesConsent" */ '~/Partials/CookiesConsentSettings/CookiesConsent').then(
            exports => exports.CookiesConsent
        ),
    GtmPageStats: () =>
        import(/* webpackChunkName: "GtmPageStats" */ '~/Partials/GtmPageStats/GtmPageStats').then(
            exports => exports.GtmPageStats
        ),
    EmployeeDetails: () =>
        import(/* webpackChunkName: "EmployeeDetails" */ '~/Pages/EmployeePage/EmployeeDetails').then(
            exports => exports.EmployeeDetails
        ),
    EmployeeListSearch: () =>
        import(/* webpackChunkName: "EmployeeListSearch" */ '~/Pages/EmployeeListPage/EmployeeListSearch').then(
            exports => exports.EmployeeListSearch
        ),
    Header: () => import(/* webpackChunkName: "Header" */ '~/Partials/Header/Header').then(exports => exports.Header),
    Letters: () =>
        import(/* webpackChunkName: "Letters" */ '~/Partials/Letters/Letters').then(exports => exports.Letters),
    Link: () => import(/* webpackChunkName: "Link" */ '~/Partials/Link/Link').then(exports => exports.Link),
    LegacyLinkList: () =>
        import(/* webpackChunkName: "LegacyLinkList" */ '~/Partials/LegacyLinkList/LegacyLinkList').then(
            exports => exports.LegacyLinkList
        ),
    LinkListSection: () =>
        import(/* webpackChunkName: "LinkListSection" */ '~/Partials/LinkListSection/LinkListSection').then(
            exports => exports.LinkListSection
        ),
    LoginForm: () =>
        import(/* webpackChunkName: "LoginForm" */ '~/Pages/LoginPage/LoginForm/LoginForm').then(
            exports => exports.LoginForm
        ),
    MyAdmin: () =>
        import(/* webpackChunkName: "MyAdmin" */ '~/Pages/MyAdminPage/Components/MyAdmin').then(
            exports => exports.MyAdmin
        ),
    NotificationBanner: () =>
        import(/* webpackChunkName: "NotificationBanner" */ '~/Features/NotificationBanner/NotificationBanner').then(
            exports => exports.NotificationBanner
        ),
    NotificationPopup: () =>
        import(/* webpackChunkName: "NotificationPopup" */ '~/Features/NotificationPopup/NotificationPopup').then(
            exports => exports.NotificationPopup
        ),
    ReadingProgressBar: () =>
        import(/* webpackChunkName: "ReadingProgressBar" */ '~/Partials/ReadingProgressBar/ReadingProgressBar').then(
            exports => exports.ReadingProgressBar
        ),
    SchoolsAndDepartments: () =>
        import(
            /* webpackChunkName: "SchoolsAndDepartments" */ '~/Partials/SchoolsAndDepartments/SchoolsAndDepartments'
        ).then(exports => exports.SchoolsAndDepartments),
    SimpleContactUs: () =>
        import(/* webpackChunkName: "SimpleContactUs" */ '~/Blocks/SimpleContactUs/SimpleContactUs').then(
            exports => exports.SimpleContactUs
        ),
    SingleContactBlock: () =>
        import(/* webpackChunkName: "SingleContactBlock" */ '~/Blocks/SingleContactBlock/SingleContactBlock').then(
            exports => exports.SingleContactBlock
        ),
    StudyModel: () =>
        import(
            /* webpackChunkName: "StudyModel" */ '~/Pages/CampusStudyPage/View/Components/StudyModel/StudyModel'
        ).then(exports => exports.StudyModel),
    StudyListSearch: () =>
        import(/* webpackChunkName: "StudyListSearch" */ '~/Pages/StudyListPage/StudyListSearch/StudyListSearch').then(
            exports => exports.StudyListSearch
        ),
    StudyPageGtmEvents: () =>
        import(/* webpackChunkName: "StudyPageGtmEvents" */ '~/Pages/StudyPageBase/StudyPageGtmEvents').then(
            exports => exports.StudyPageGtmEvents
        ),
    SwitchToTabButton: () =>
        import(/* webpackChunkName: "SwitchToTabButton" */ '~/Partials/SwitchToTabButton/SwitchToTabButton').then(
            exports => exports.SwitchToTabButton
        ),
    TabsBar: () =>
        import(/* webpackChunkName: "TabsBar" */ '~/Partials/TabsBar/TabsBar').then(exports => exports.TabsBar),
    TrialApplicationBlock: () =>
        import(
            /* webpackChunkName: "TrialApplicationBlock" */ '~/Blocks/TrialApplicationBlock/TrialApplicationBlock'
        ).then(exports => exports.TrialApplicationBlock),
    VimeoVideoFigure: () =>
        import(/* webpackChunkName: "VimeoVideoFigure" */ '~/Partials/VideoFigure/VimeoVideoFigure').then(
            exports => exports.VimeoVideoFigure
        ),
    YouTubeVideoFigure: () =>
        import(/* webpackChunkName: "YouTubeVideoFigure" */ '~/Partials/VideoFigure/YouTubeVideoFigure').then(
            exports => exports.YouTubeVideoFigure
        ),
    SyllabusListSearch: () =>
        import(/* webpackChunkName: "SyllabusListSearch" */ '~/Pages/SyllabusListPage/SyllabusListSearch').then(
            exports => exports.SyllabusListSearch
        ),
    SubjectInfoContent: () =>
        import(
            /* webpackChunkName: "SubjectInfoContent" */ '~/Pages/SubjectInfoPage/Components/SubjectInfoContent'
        ).then(exports => exports.SubjectInfoContent),
    OriaSearch: () =>
        import(/* webpackChunkName: "OriaSearch" */ '~/Blocks/OriaSearch/OriaSearch').then(
            exports => exports.OriaSearch
        ),
    ProgramInfoPage: () =>
        import(/* webpackChunkName: "ProgramInfoPage" */ '~/Pages/ProgramInfoPage/Components/ProgramInfoPage').then(
            exports => exports.ProgramInfoPage
        ),
    ShareButton: () =>
        import(/* webpackChunkName: "ShareButton" */ '~/Partials/ShareButton/ShareButton').then(
            exports => exports.ShareButton
        ),
    ResearchArticleListContent: () =>
        import(
            /* webpackChunkName: "ResearchArticleListContent" */ '~/Pages/ResearchArticleListPage/ResearchArticleListContent/ResearchArticleListContent'
        ).then(exports => exports.ResearchArticleListContent),
    AccordionBlockWithRemoteExpand: () =>
        import(
            /* webpackChunkName: "AccordionBlockWithRemoteExpand" */ '~/Partials/AccordionBlockWithRemoteExpand/AccordionBlockWithRemoteExpand'
        ).then(exports => exports.AccordionBlockWithRemoteExpand),
    EducationElementTable: () =>
        import(
            /* webpackChunkName: "EducationElementTable" */ '~/Partials/Table/EducationElement/EducationElementTable'
        ).then(exports => exports.EducationElementTable),
    ResearchProjectContent: () =>
        import(
            /* webpackChunkName: "ResearchProjectContent" */ '~/Pages/ResearchProjectPage/ResearchProjectContent'
        ).then(exports => exports.ResearchProjectContent),
    LinkBar: () =>
        import(/* webpackChunkName: "LinkBar" */ '~/Partials/LinkBar/LinkBar').then(exports => exports.LinkBar),
    ImageBackgroundCallToActionBlock: () =>
        import(
            /* webpackChunkName: "ImageBackgroundCallToActionBlock" */ '~/Blocks/ImageBackgroundCallToAction/View/ImageBackgroundCallToActionBlock'
        ).then(exports => exports.ImageBackgroundCallToActionBlock),
    ContentCard: () =>
        import(/* webpackChunkName: "ContentCard" */ '~/Blocks/ContentCard/ContentCard').then(
            exports => exports.ContentCard
        ),
    ContentCardListBlock: () =>
        import(
            /* webpackChunkName: "ContentCardListBlock" */ '~/Blocks/ContentCardListBlock/ContentCardListBlock'
        ).then(exports => exports.ContentCardListBlock),
    ContentList: () =>
        import(/* webpackChunkName: "ContentList" */ '~/Blocks/ContentListBlock/ContentList').then(
            exports => exports.ContentList
        ),
    DepartmentLinkList: () =>
        import(/* webpackChunkName: "DepartmentLinkList" */ '~/Pages/SchoolPage/Components/DepartmentLinkList').then(
            exports => exports.DepartmentLinkList
        ),
    SchoolPageSections: () =>
        import(/* webpackChunkName: "SchoolPageSections" */ '~/Pages/SchoolPage/Components/SchoolPageSections').then(
            exports => exports.SchoolPageSections
        ),
    DepartmentPageSections: () =>
        import(
            /* webpackChunkName: "DepartmentPageSections" */ '~/Pages/DepartmentPage/Components/DepartmentPageSections'
        ).then(exports => exports.DepartmentPageSections),
    NavigationBlock: () =>
        import(/* webpackChunkName: "NavigationBlock" */ '~/Partials/NavigationBlock/NavigationBlock').then(
            exports => exports.NavigationBlock
        ),
    LibraryPageHeader: () =>
        import(/* webpackChunkName: "LibraryPageHeader" */ '~/Pages/LibraryPage/LibraryPageHeader').then(
            exports => exports.LibraryPageHeader
        ),
    AlertSectionBlock: () =>
        import(/* webpackChunkName: "AlertSectionBlock" */ '~/Blocks/AlertSectionBlock/View/AlertSectionBlock').then(
            exports => exports.AlertSectionBlock
        ),
    Callout: () =>
        import(/* webpackChunkName: "Callout" */ '~/Partials/Callout/Callout').then(exports => exports.Callout),
    CalloutList: () =>
        import(/* webpackChunkName: "CalloutList" */ '~/Partials/CalloutList/CalloutList').then(
            exports => exports.CalloutList
        ),
    ContactListBlock: () =>
        import(/* webpackChunkName: "ContactListBlock" */ '~/Blocks/ContactListBlock/ContactListBlock').then(
            exports => exports.ContactListBlock
        ),
    Footer: () => import(/* webpackChunkName: "Footer" */ '~/Partials/Footer/Footer').then(exports => exports.Footer),
    ContentCardWithImage: () =>
        import(
            /* webpackChunkName: "ContentCardWithImage" */ '~/Partials/ContentCardWithImage/ContentCardWithImage'
        ).then(exports => exports.ContentCardWithImage),
    ContentCardWithImageBlock: () =>
        import(
            /* webpackChunkName: "ContentCardWithImageBlock" */ '~/Blocks/ContentCardWithImageBlock/ContentCardWithImageBlock'
        ).then(exports => exports.ContentCardWithImageBlock),
    QuizPageHero: () =>
        import(
            /* webpackChunkName: "QuizPageHero" */ '~/Pages/QuizPage/View/Components/QuizPageHero/QuizPageHero'
        ).then(exports => exports.QuizPageHero),
    HomeHero: () =>
        import(/* webpackChunkName: "HomeHero" */ '~/Blocks/HomeHero/View/Components/HomeHero/HomeHero').then(
            exports => exports.HomeHero
        ),
    CalendarBlock: () =>
        import(/* webpackChunkName: "CalendarBlock" */ '~/Blocks/CalendarBlock/View/CalendarBlock').then(
            exports => exports.CalendarBlock
        ),
    JobOpportunitiesSectionBlock: () =>
        import(
            /* webpackChunkName: "JobOpportunitiesSectionBlock" */ '~/Blocks/JobOpportunitiesSectionBlock/View/JobOpportunitiesSectionBlock'
        ).then(exports => exports.JobOpportunitiesSectionBlock),
    ArticleListBlock: () =>
        import(/* webpackChunkName: "ArticleListBlock" */ '~/Blocks/ArticleListBlock/View/ArticleListBlock').then(
            exports => exports.ArticleListBlock
        ),
    ApplicationProcessSectionBlock: () =>
        import(
            /* webpackChunkName: "ApplicationProcessSectionBlock" */ '~/Blocks/ApplicationProcessSectionBlock/View/ApplicationProcessSectionBlock'
        ).then(exports => exports.ApplicationProcessSectionBlock),
    BookMeetingSectionBlock: () =>
        import(
            /* webpackChunkName: "BookMeetingSectionBlock" */ '~/Blocks/BookMeetingSectionBlock/View/BookMeetingSectionBlock'
        ).then(exports => exports.BookMeetingSectionBlock),
    GobiSectionBlock: () =>
        import(/* webpackChunkName: "GobiSectionBlock" */ '~/Blocks/GobiSectionBlock/View/GobiSectionBlock').then(
            exports => exports.GobiSectionBlock
        ),
    TestimoniesSectionBlock: () =>
        import(
            /* webpackChunkName: "TestimoniesSectionBlock" */ '~/Blocks/TestimoniesSectionBlock/View/TestimoniesSectionBlock'
        ).then(exports => exports.TestimoniesSectionBlock),
    QuoteSectionBlock: () =>
        import(/* webpackChunkName: "QuoteSectionBlock" */ '~/Blocks/QuoteSectionBlock/View/QuoteSectionBlock').then(
            exports => exports.QuoteSectionBlock
        ),
    PracticalInformationSectionBlock: () =>
        import(
            /* webpackChunkName: "PracticalInformationSectionBlock" */ '~/Blocks/PracticalInformationSectionBlock/View/PracticalInformationSectionBlock'
        ).then(exports => exports.PracticalInformationSectionBlock),
    CampusStudyPageHero: () =>
        import(
            /* webpackChunkName: "CampusStudyPageHero" */ '~/Pages/CampusStudyPage/View/Components/CampusStudyPageHero/CampusStudyPageHero'
        ).then(exports => exports.CampusStudyPageHero),
    Breadcrumbs: () =>
        import(/* webpackChunkName: "Breadcrumbs" */ '~/Partials/Breadcrumbs/Breadcrumbs').then(
            exports => exports.Breadcrumbs
        ),
    StudyPageNavigation: () =>
        import(
            /* webpackChunkName: "StudyPageNavigation" */ '~/Pages/CampusStudyPage/View/Components/StudyPageNavigation/StudyPageNavigation'
        ).then(exports => exports.StudyPageNavigation),
    StudyModelSectionBlock: () =>
        import(
            /* webpackChunkName: "StudyModelSectionBlock" */ '~/Blocks/StudyModelSectionBlock/View/StudyModelSectionBlock'
        ).then(exports => exports.StudyModelSectionBlock),
    StudyPortfolioSectionBlock: () =>
        import(
            /* webpackChunkName: "StudyPortfolioSectionBlock" */ '~/Blocks/StudyPortfolioSectionBlock/View/StudyPortfolioSectionBlock'
        ).then(exports => exports.StudyPortfolioSectionBlock),
    FactsSectionBlock: () =>
        import(/* webpackChunkName: "FactsSectionBlock" */ '~/Blocks/FactsSectionBlock/View/FactsSectionBlock').then(
            exports => exports.FactsSectionBlock
        ),
    OnlineStudyPlannerSectionBlock: () =>
        import(
            /* webpackChunkName: "OnlineStudyPlannerSectionBlock" */ '~/Pages/OnlineStudyPage/View/Components/OnlineStudyPlannerSectionBlock'
        ).then(exports => exports.OnlineStudyPlannerSectionBlock),
    HeroBlock: () =>
        import(/* webpackChunkName: "HeroBlock" */ '~/Blocks/HeroBlock/View/HeroBlock').then(
            exports => exports.HeroBlock
        ),
    FactBoxBlock: () =>
        import(/* webpackChunkName: "FactBoxBlock" */ '~/Blocks/FactBoxBlock/View/FactBoxBlock').then(
            exports => exports.FactBoxBlock
        ),
    ContentDoubleCardWithImageBlock: () =>
        import(
            /* webpackChunkName: "ContentDoubleCardWithImageBlock" */ '~/Blocks/ContentDoubleCardWithImageBlock/View/ContentDoubleCardWithImageBlock'
        ).then(exports => exports.ContentDoubleCardWithImageBlock),
    CardsSectionBlock: () =>
        import(/* webpackChunkName: "CardsSectionBlock" */ '~/Blocks/CardsSectionBlock/View/CardsSectionBlock').then(
            exports => exports.CardsSectionBlock
        ),
    StudyFilterSectionBlock: () =>
        import(
            /* webpackChunkName: "StudyFilterSectionBlock" */ '~/Blocks/StudyFilterSectionBlock/View/StudyFilterSectionBlock'
        ).then(exports => exports.StudyFilterSectionBlock),
    CampusApplicationApplet: () =>
        import(
            /* webpackChunkName: "CampusApplicationApplet" */ '~/Features/ApplicationApplets/Campus/CampusApplicationApplet'
        ).then(exports => exports.default),
    OnlineApplicationApplet: () =>
        import(
            /* webpackChunkName: "OnlineApplicationApplet" */ '~/Features/ApplicationApplets/Online/OnlineApplicationApplet'
        ).then(exports => exports.default),
    EventListPage: () =>
        import(/* webpackChunkName: "EventListPage" */ '~/Pages/EventListPage/Components/EventListPage').then(
            exports => exports.EventListPage
        ),
    AccordionBlockStyles: () =>
        import(/* webpackChunkName: "AccordionBlockStyles" */ '~/Blocks/AccordionBlock/AccordionBlockStyles').then(
            exports => exports.AccordionBlockStyles
        ),
    ContentLinkListStyles: () =>
        import(/* webpackChunkName: "ContentLinkListStyles" */ '~/Blocks/ContentLinkList/ContentLinkListStyles').then(
            exports => exports.ContentLinkListStyles
        ),
    HeroLegacyStyles: () =>
        import(/* webpackChunkName: "HeroLegacyStyles" */ '~/Blocks/HeroLegacy/HeroLegacyStyles').then(
            exports => exports.HeroLegacyStyles
        ),
    HomeHeroLegacyStyles: () =>
        import(/* webpackChunkName: "HomeHeroLegacyStyles" */ '~/Blocks/HomeHeroLegacy/HomeHeroLegacyStyles').then(
            exports => exports.HomeHeroLegacyStyles
        ),
    IFrameBlockStyles: () =>
        import(/* webpackChunkName: "IFrameBlockStyles" */ '~/Blocks/IFrameBlock/IFrameBlockStyles').then(
            exports => exports.IFrameBlockStyles
        ),
    ImageWithLettersStyles: () =>
        import(
            /* webpackChunkName: "ImageWithLettersStyles" */ '~/Blocks/ImageWithLetters/ImageWithLettersStyles'
        ).then(exports => exports.ImageWithLettersStyles),
    InlineImagePlaceholderStyles: () =>
        import(
            /* webpackChunkName: "InlineImagePlaceholderStyles" */ '~/Blocks/InlineImagePlaceholder/InlineImagePlaceholderStyles'
        ).then(exports => exports.InlineImagePlaceholderStyles),
    MessageBlockStyles: () =>
        import(/* webpackChunkName: "MessageBlockStyles" */ '~/Blocks/MessageBlock/MessageBlockStyles').then(
            exports => exports.MessageBlockStyles
        ),
    ResearchArticleFooterStyles: () =>
        import(
            /* webpackChunkName: "ResearchArticleFooterStyles" */ '~/Blocks/ResearchArticleFooterBlock/ResearchArticleFooterStyles'
        ).then(exports => exports.ResearchArticleFooterStyles),
    SidebarImageStyles: () =>
        import(/* webpackChunkName: "SidebarImageStyles" */ '~/Blocks/SidebarImage/SidebarImageStyles').then(
            exports => exports.SidebarImageStyles
        ),
    TextSectionStyles: () =>
        import(/* webpackChunkName: "TextSectionStyles" */ '~/Blocks/TextSectionBlock/View/TextSectionStyles').then(
            exports => exports.TextSectionStyles
        ),
    PersonCardStyles: () =>
        import(/* webpackChunkName: "PersonCardStyles" */ '~/Partials/PersonCard/PersonCardStyles').then(
            exports => exports.PersonCardStyles
        ),
    CallToActionBannerStyles: () =>
        import(
            /* webpackChunkName: "CallToActionBannerStyles" */ '~/Partials/CallToActionBanner/CallToActionBannerStyles'
        ).then(exports => exports.CallToActionBannerStyles),
    GalleryBlock: () =>
        import(/* webpackChunkName: "GalleryBlock" */ '~/Blocks/GalleryBlock/View/GalleryBlock').then(
            exports => exports.GalleryBlock
        ),
};

global.IMPORT_COMPONENT = (componentName: string): Promise<ComponentType<any>> => {
    const dynamicImportInitiator = ComponentsDynamicImports[componentName];

    if (!dynamicImportInitiator) {
        throw new Error(`Component ${componentName} could not be found`);
    }

    return dynamicImportInitiator();
};

global.LOAD_COMPONENTS = async (componentNames: string[]) => {
    const onComponentsLoaded = global.ON_COMPONENTS_LOADED;

    if (!onComponentsLoaded) {
        throw new Error('This function should only be called once.');
    }

    global.ON_COMPONENTS_LOADED = undefined;

    const promises = componentNames.map(name => global.IMPORT_COMPONENT(name));
    const results = await Promise.all(promises);

    for (let i = 0; i < componentNames.length; i++) {
        const name = componentNames[i];
        const component = results[i];

        registerComponent(name, component);
    }

    onComponentsLoaded();
};

global.document.addEventListener(
    'DOMContentLoaded',
    () => {
        const componentsToLoad = global.COMPONENTS_TO_LOAD;

        if (!componentsToLoad) {
            throw new Error('Components to load has not been defined during SSR render.');
        }

        return global.LOAD_COMPONENTS(componentsToLoad);
    },
    { once: true }
);
